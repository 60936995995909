.logo-image-width {
  width: 100%;
}

.nav-bar-padding {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.nav-link-bar {
  background: #5c187c;
  padding: 10px 18px 10px 18px !important;
  margin-right: 10px !important;
  border-radius: 2px !important;
  display: inline !important;
  text-align: start !important;
}

.nav-link-bar1 {
  padding: 10px 18px 10px 18px !important;
  margin-right: 15px !important;
  border-radius: 2px !important;
  display: inline !important;
  text-align: start !important;
  background: linear-gradient(to right, #5c187c 50%, rgb(233 254 204) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

/* .go2150698616 {
  display: inline-block !important;
  text-align: center !important;
  line-height: 4.8rem !important;
  padding: 0 0.7rem !important;
  cursor: pointer !important;
  min-width: 13.5rem !important;
  color: silver !important;
  border-bottom: 2px solid silver !important;
} */
.nav-link-bar1:hover {
  background-position: left bottom;
  cursor: pointer !important;
}

.card-design-services3 {
  /* min-height: 140px; */
  overflow: hidden;
  position: relative;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
  z-index: 0 !important;
  background-color: white !important;
}

.card-design-services3:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0px;
  right: -0px;
  background: #c14b4ea8;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.new-margin-auto {
  margin: 0 auto !important;
}

.new-image-width {
  width: 100% !important;
}

.steps-text-margin {
  margin-top: 2rem !important;
}

.dash-text-decoration {
  text-decoration: none !important;
}

.card-design-services3:hover:before {
  transform: scale(21);
}

.card-design-services3:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card-design-services3:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.nav-link-bar-text1 {
  font-weight: 600;
  color: black;
  font-size: 15px;
  text-decoration: none;
}

.nav-link-bar1:hover .nav-link-bar-text1 {
  font-weight: 600;
  color: white;
  font-size: 15px;
  text-decoration: none;
}

.nav-link-bar-text {
  font-weight: 600;
  color: white;
  font-size: 15px;
  text-decoration: none;
}

.new-box-color {
  background: rgb(233 254 204);
  padding-top: 4rem !important;
  position: relative;
  width: 100%;
  padding-bottom: 4rem !important;
}

.new-box-color1 {
  background: rgb(233 254 204);
  padding-top: 1rem !important;
  position: relative;
  width: 100%;
  padding-bottom: 4rem !important;
}

.body-h1-text {
  line-height: 1.5em !important;
  font-size: 56px !important;
  font-weight: 700 !important;
  text-align: start;
  font-family: "Noto Serif", serif;
  color: #2a2a2a !important;
  padding-top: 2rem;
  text-transform: uppercase;
}

.images-design-slider {
  border-radius: 50%;
  border: 13px solid white;
  z-index: 7;
  width: 100%;
  height: 304px !important;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
}

.carasoul-margin {
  position: relative !important;
  left: 20rem !important;
  top: -13rem !important;
  margin-bottom: -12rem !important;
}

.fixed-design {
  position: fixed;
  top: 40%;
  right: 0;
}

.new-image-fontSize {
  font-size: 17px !important;
  color: black !important;
  font-weight: 700 !important;
  margin-top: 1rem !important;
}

.search-icon-bar {
  font-size: 20px !important;
  color: black !important;
  text-align: right !important;
}

.small-body-text {
  font-size: 16px !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
  margin-top: 2rem !important;
  text-align: justify !important;
  font-family: "Roboto", serif;
}

.body-h1-text112 {
  background-color: #5c187c !important;
  position: absolute;
  top: 4rem !important;
  line-height: 1.2em !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  text-align: start;
  font-family: "Noto Serif", serif;
  color: white !important;
  right: 0rem !important;
  padding: 20px 29px;
  border-radius: 0px;
}

.small-body-text1 {
  font-size: 16px !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;
  text-align: justify !important;
  font-family: "Roboto", serif;
}

.new-style-padding {
  padding-right: 7rem !important;
}

.sub-header-icon-design {
  background: #5c187c;
  color: white;
  padding: 9px 11px;
  border-radius: 10px 0px 10px 0px;
  position: relative;
  top: 12px;
  right: 5px;
}

.side-design-sub-header {
  border-right: 2px solid;
  height: 37px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 20px;
}

.my-box-design {
  background-color: #e9fecc !important;
  border-radius: 52%;
  height: 52%;
  padding: 10% !important;
  position: absolute;
  right: 29%;
  top: 32%;
  width: 23%;
}

.my-box-design1 {
  background-color: rgb(233 254 204);
  padding: 10px;
  position: absolute;
  right: 32rem;
  top: 30rem;
  width: 50px;
  height: 32px;
}

.small-body-text2 {
  font-size: 15px !important;
  color: white !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;
  text-align: justify !important;
  font-family: "Roboto", serif;
}

.small-body-text3 {
  font-size: 16px !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
  margin-top: 0.7rem !important;
  /* text-align: justify !important; */
  font-family: "Roboto", serif;
}

.contact-us-button {
  width: 100% !important;
  color: white !important;
  background: #5c187c !important;
  border: none !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.contact-us-button:hover {
  transform: scale(1.2);
  background-color: #138991 !important;
}

.contact-us-button-icon {
  font-size: 20px !important;
  position: relative !important;
  left: 2px !important;
}

.video-icons {
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1.5px solid #5c187c;
  font-size: 20px;
  width: 100% !important;
  border-radius: 50% !important;
  color: #5c187c !important;
}

.video-icons:hover {
  background-color: #5c187c !important;
  cursor: pointer !important;
  color: white !important;
}

.video-icons-text {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.2em !important;
  margin-left: 10px !important;
}

.video-icons-text1 {
  padding-top: 10px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.2em !important;
  margin-left: 10px !important;
}

.new-position-images {
  position: relative !important;
  left: 11.78rem;
  top: -11.7rem;
}

.text-fonts {
  color: #5a187a;
  text-align: start !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.text-fonts1 {
  color: #5a187a;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.body-font-size {
  font-size: 50px !important;
  color: black;
  font-weight: 700 !important;
  text-align: start !important;
}

.body-font-size1 {
  font-size: 50px !important;
  color: black;
  font-weight: 700 !important;
}

.data-fonts {
  font-size: 50px !important;
  color: #5c187c !important;
  font-weight: 700 !important;
}

.drop-down-list {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s, opacity 0.5s, top 0.3s linear;
  background-color: #5c187c;
  position: absolute !important;
  left: 0rem !important;
  top: 5rem !important;
  width: 25rem !important;
  z-index: 8 !important;
  /* transition: all 0.52s ease-out 0.5s !important; */
}

.drop-down:hover .drop-down-list {
  visibility: visible !important;
  opacity: 1 !important;
  top: 2.5rem !important;
}

.my-dropdown-list-items {
  border-bottom: 1.5px solid white !important;
  padding: 16px !important;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important;
  text-decoration: none !important;
  display: block !important;
}

.my-dropdown-list-items:hover {
  background-color: #138991 !important;
}

.MuiInputBase-root input {
  font-size: 14px !important;
}

.MuiSvgIcon-root {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.3rem !important;
  flex-shrink: 0;
  user-select: none;
  background-color: none !important;
}

.card-down-design {
  margin: 1rem;
  background: white;
  position: relative;
  top: -4rem;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
  z-index: 2 !important;
  cursor: pointer;
  margin-bottom: 0;
}

.face-treatment-text {
  font-size: 21px !important;
  color: #5a187a;
  font-weight: 700 !important;
}

.card-down-design .display-block-text {
  cursor: pointer;
  visibility: hidden !important;
  height: 0px;
  opacity: 0;
  transition: height 0s, opacity 0.5s linear;
}

.hover-display-information:hover .card-down-design .display-block-text {
  cursor: pointer !important;
  width: 100% !important;
  visibility: visible !important;
  opacity: 1;
  height: 140px;
}

.hover-display-information:hover .image-design-hover {
  opacity: 0.6;
  transform: scale(1.1);
}

.images-hover-design-gallery {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.images-hover-design-gallery:hover {
  opacity: 0.6;
}

.medium-box-design {
  position: absolute;
  background: #ffffffc7;
  width: 71%;
  left: 20px;
  right: 10rem !important;
  z-index: 9999 !important;
  border-radius: 10px;
  bottom: -18px;
  height: 30px !important;
  padding-left: 2rem !important;
  padding-right: 2rem;
}

.small-box-design {
  position: absolute;
  background: #ffffffb0;
  width: 61%;
  left: 35px;
  right: 10rem !important;
  z-index: 9999 !important;
  border-radius: 10px;
  bottom: -33px;
  height: 43px !important;
  padding-left: 2rem !important;
  padding-right: 2rem;
  margin-bottom: 0;
}

.table-image {
  height: 60px;
  width: 80px;
}

.new-cards-shadow {
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%) !important;
  background: rgb(239, 218, 250);
}

.new-articles-text {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 1.2em !important;
  text-align: start !important;
}

.new-articles-text1 {
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 1.2em !important;
}

.image-design-hover {
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.new-articles-text2 {
  font-size: 25px !important;
  font-weight: 600 !important;
  line-height: 1.2em !important;
  text-align: start !important;
  color: white !important;
}

.appointment-images {
  /* background-image: url("../images/background.jpg") !important; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.appointment-images1 {
  /* background-image: url("../images/background1.jpg") !important; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.new-back-color {
  background-color: #0000004f !important;
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.appointment-card-design {
  background-color: white !important;
  padding: 3rem !important;
}

.appointment-card-design1 {
  background-color: white !important;
  padding: 1.5rem !important;
  border-radius: 10px;
}

.icon-design {
  background: #5c187c;
  padding: 11px 13px;
  border-radius: 5px;
}

.main-background-color {
  background-color: #5a187a !important;
  padding: 2rem !important;
}

.news-letter-text {
  color: white !important;
  font-size: 45px !important;
  text-align: start !important;
  font-weight: 700 !important;
  line-height: 1.2em;
  font-family: "Noto Serif", serif !important;
}

.form-control {
  background: white;
  border: none;
  border-radius: 0px;
  width: 100%;
  height: 45px;
  padding: 0px 4px;
}

.form-control1 {
  background: white;
  border: none;
  border-radius: 0px;
  width: 97%;
  height: 45px;
  padding: 0px 4px;
}

.subscribe-button-style {
  width: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  padding: 10.5px 13px !important;
  font-size: 16px !important;
}

.footer-background {
  background-color: rgb(233 254 204) !important;
  padding: 3rem !important;
  margin-top: 4rem !important;
}

.icon-footer-design {
  background: #5a1879;
  color: white;
  padding: 10px 12px;
  border-radius: 5px;
}

.icon-footer-design1 {
  background: #149ef8;
  color: white;
  padding: 14px 16px;
  border-radius: 0px;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
  font-size: 18px !important;
  margin-bottom: -4px;
  border-radius: 5px 0px 0px 0px;
}

.icon-footer-design2 {
  background: #f04f02;
  color: white;
  padding: 14px 14px;
  border-radius: 0px;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
  font-size: 18px !important;
  margin-bottom: -4px;
}

.icon-footer-design3 {
  background: #24d366;
  color: white;
  padding: 14px 14px;
  border-radius: 0px;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
  font-size: 18px !important;
  margin-bottom: -4px;
}

.icon-footer-design4 {
  background: black;
  color: white;
  padding: 14px 14px;
  border-radius: 0px;
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%);
  font-size: 18px !important;
  margin-bottom: -4px;
  border-radius: 0px 0px 0px 5px;
}

.footer-service-text {
  font-size: 20px !important;
  color: black !important;
  font-weight: 600 !important;
}

.footer-service-link {
  font-size: 15px !important;
  color: #6b6b6b !important;
  display: block;
  text-decoration: none;
  font-weight: 600 !important;
  margin-bottom: 12px;
}

.footer-service-link1 {
  font-size: 15px !important;
  color: #6b6b6b !important;
  /* display: block; */
  text-decoration: none;
  font-weight: 600 !important;
  margin-bottom: 12px;
}

.join-button {
  width: 100%;
  border: none;
  background: #5c187c;
  color: white;
  padding: 8px 0px 10px 0px;
}

.footer-border-bottom {
  border-bottom: 1.5px solid #eaeaea;
  padding-bottom: 3rem !important;
}

.footer-bottom-bar {
  padding-top: 2rem !important;
}

.footer-bottom-bar-text {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.dashboard-side-bar-color {
  background-color: #101c44 !important;
  position: fixed !important;
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
  /* left: .8rem ; */
  margin-left: 0.8rem !important;
  height: calc(98vh - 2rem);
  border-radius: 15px !important;
  padding: 10px !important;
  overflow-y: scroll !important;
  position: fixed;
  width: 210px;
}

.admin-dashboard-text {
  color: white;
  margin-top: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  /* border-bottom: 1px solid white; */
  padding-bottom: 0rem !important;
  text-align: start !important;
  margin-left: 10px !important;
}

.dashboard-side-bar-link {
  background: #f5365b;
  border-radius: 5px;
  padding: 15px 12px 15px 13px !important;
  margin-bottom: 6px !important;
}

.dashboard-side-bar-link1 {
  border-radius: 5px;
  padding: 15px 12px 15px 13px !important;
  margin-bottom: 6px !important;
}

.dashboard-side-bar-link1:hover {
  background: #f5365b;
}

.dashboard-side-bar-link-text {
  font-size: 14px !important;
  color: white !important;
  font-weight: 500 !important;
  text-decoration: none !important;
  line-height: 1.2em !important;
}

.input-height {
  height: auto !important;
}

.dashboard-full-background {
  background-color: #1c284c !important;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.dashboard-header-text {
  text-align: start;
  color: white;
  font-size: 19px !important;
  font-weight: 600 !important;
}

.dashboard-card-design {
  background-color: #101c44 !important;
  padding: 1rem !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  border-radius: 10px !important;
  box-shadow: rgb(0 0 0 / 8%) 0rem 0rem 1rem 0rem !important;
}

.dashboard-card-design-text {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 15px !important;
  text-align: start !important;
  font-weight: 600 !important;
}

.dashboard-appointment-list {
  background: linear-gradient(310deg, rgb(17, 113, 239), rgb(17, 205, 239));
  color: white;
  font-size: 20px;
  padding: 15px 15.5px;
  border-radius: 50%;
}

.appointment-list-text {
  font-size: 30px !important;
  text-align: start !important;
  margin-top: 10px !important;
  font-weight: 700 !important;
  color: white !important;
  line-height: 1.2em !important;
  letter-spacing: 0.2rem !important;
}

.daily-appointment-percentage {
  color: rgba(255, 255, 255, 0.8) !important;
  margin-top: 8px !important;
  font-size: 14px !important;
  text-align: start !important;
  font-weight: 500 !important;
}

.appointment-approve-btn {
  background-color: rgb(17, 205, 239) !important;
  color: white;
  font-size: 16px !important;
  padding: 4px 5px;
  border-radius: 5px !important;
  margin-right: 3px !important;
}

.appointment-approve-btn1 {
  background-color: #f5365b !important;
  color: white;
  font-size: 16px !important;
  padding: 4px 5px;
  border-radius: 5px !important;
  margin-right: 5px !important;
}

.appointment-approve-btn2 {
  background-color: rgb(17, 113, 239) !important;
  color: white;
  font-size: 16px !important;
  padding: 4px 5px;
  border-radius: 5px !important;
  /* margin-right: 4px !important; */
}

.detail-page-fonts {
  font-size: 17px !important;
  font-family: "Roboto", Sans-serif !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
}

.detail-page-fonts1 {
  font-size: 17px !important;
  font-family: "Roboto", Sans-serif !important;
  color: #6b6b6b !important;
  font-weight: 600 !important;
}

.product-card-design {
  background: rgb(239, 218, 250) !important;
  padding: 1rem !important;
  /* border-radius: 10px !important; */
  box-shadow: 0px 12px 15px -15px rgb(0 0 0 / 50%) !important;
}

.heart-icon-design {
  position: relative !important;
  top: -3.1rem !important;
  right: -5.5rem !important;
  background: #ffffffc7 !important;
  padding: 11px 11px 10px 11px !important;
  border-radius: 50px !important;
}

.product-name {
  margin-top: -1.5rem !important;
  text-align: start !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Roboto", Sans-serif !important;
}

.product-price {
  font-size: 16px !important;
  text-align: start !important;
  margin-top: 7px !important;
  color: #5a187a !important;
  font-family: "Roboto", Sans-serif !important;
}

.product-price1 {
  font-size: 16px !important;
  text-align: start !important;
  margin-top: 7px !important;
  color: black !important;
  font-family: "Roboto", Sans-serif !important;
}

.table-design-background {
  background-color: white;
  margin: 2rem !important;
  border-radius: 10px !important;
}

.dashboard-home-page-text {
  text-align: start !important;
  padding-top: 1.5rem !important;
  padding-left: 1.5rem !important;
  font-size: 19px !important;
  color: #1b284b !important;
  font-weight: 600 !important;
  padding-bottom: 1rem !important;
}

.modal-text-design {
  font-size: 20px !important;
  color: black;
  font-weight: 600 !important;
  margin-bottom: 1.3rem !important;
}

.yes-button {
  background: #1f285a;
  border: none;
  padding: 5px 12px 6px 12px;
  color: white;
  border-radius: 5px;
  margin-right: 8px;
  font-size: 13px;
  font-weight: 600;
}

.no-button {
  background: #f44336;
  border: none;
  padding: 5px 12px 6px 12px;
  color: white;
  border-radius: 5px;
  margin-right: 4px;
  font-size: 13px;
  font-weight: 600;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #c4c4c4;
  display: inline-block;
  padding: 0px 0px;
  cursor: pointer;
  width: 100% !important;
  border-radius: 5px !important;
}

.choose-file-text {
  background: #02554d;
  color: white;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 16px 7px;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
}

.file-name-display-design {
  font-size: 12px !important;
  padding: 16px 0px !important;
  font-weight: 600 !important;
}

.file-preview {
  margin: 0 10px;
}

.res-margin {
  margin-top: 5rem !important;
}

.new-res-margin {
  margin-top: 0rem !important;
  margin-bottom: 10rem;
}

.new-res-margin1 {
  margin-top: -7.5rem !important;
}

.new-style-margin {
  margin-top: 8rem !important;
}

.gallery-margin-top {
  margin-top: 3rem !important;
}

.blog-detail-margin {
  margin-top: 4rem !important;
  text-align: start !important;
}

.full-name-design {
  border: 1px solid #1b284b !important;
  color: #1b284b;
  padding: 16px 29px 16px 29px;
  border-radius: 20px;
  margin-top: 10px;
  margin-right: 15px !important;
  margin-bottom: 10px !important;
}

.full-name-design:hover {
  background-color: #1b284b;
  color: white;
}

.full-name-font {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.MuiFormControl-root {
  width: 100% !important;
  margin: 8px;
}

/* errorpage css */
.main {
  margin-top: 5%;
}

.st0 {
  fill: #fff;
}

.st2 {
  fill: #5d89af;
}

.st3 {
  fill: #709abf;
}

.st4,
.st6 {
  fill: #fff;
  stroke: #b3dcdf;
  stroke-miterlimit: 10;
}

.st6 {
  stroke: #5d89af;
  stroke-width: 2;
}

.st7,
.st8,
.st9 {
  stroke: #709abf;
  stroke-miterlimit: 10;
}

.st7 {
  stroke-width: 5;
  stroke-linecap: round;
  fill: none;
}

.st8,
.st9 {
  fill: #fff;
}

.st9 {
  fill: none;
}

#cloud1 {
  animation: cloud003 15s linear infinite;
}

#cloud2 {
  animation: cloud002 25s linear infinite;
}

#cloud3 {
  animation: cloud003 20s linear infinite;
}

#cloud4 {
  animation: float 4s linear infinite;
}

#cloud5 {
  animation: float 8s linear infinite;
}

#cloud7 {
  animation: float 5s linear infinite;
}

#tracks {
  animation: slide 650ms linear infinite;
}

#bumps {
  animation: land 10000ms linear infinite;
}

@keyframes jig {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
  }
}

#car-layers {
  animation: jig 0.35s linear infinite;
}

@keyframes land {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(1000px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(100px);
  }
}

/* @keyframes cloudFloat {
    0% { transform: translateX(0) translateY(3px); }
    100% { transform: translateX(1000px) translateY(0); }
  } */

@keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }

  50% {
    transform: translateY(8px) translateX(5px);
  }

  100% {
    transform: translateY(0px) translateX(0);
  }
}

#bracefront,
#braceback {
  animation: braces 1s linear infinite;
}

@keyframes braces {
  0% {
    transform: translateX(-2px);
  }

  25% {
    transform: translateX(3px);
  }

  50% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(-2px);
  }
}

.error-page-btn {
  border: 0px;
  background: linear-gradient(45deg, #c3e3e6, #75e2ec);
  padding: 19px 30px 19px 30px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

.admin-body {
  background-color: #080710 !important;
  height: 100vh !important;
}

.background-body {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background-body .shape-body {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape-body:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}

.shape-body:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}

.admin-body {
  background-color: #080710 !important;
  height: 100vh !important;
}

.background-body {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background-body .shape-body {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape-body:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}

.shape-body:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}

.admin-form {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

.admin-form * {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.admin-form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.admin-label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: start !important;
}

.admin-input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

.admin-input ::placeholder {
  color: #e5e5e5;
}

.admin-button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.successmessege {
  position: fixed;
  bottom: 20px;
  background: #4caf50;
  color: white;
  width: auto;
  padding: 12px;
  border-radius: 7px;
  right: 4px;
}

.messegefonts {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: white !important;
}

.messegeicon {
  margin-right: 7px;
  font-size: 15px !important;
}

.errormessege {
  position: fixed;
  bottom: 20px;
  background: #f44336;
  color: white;
  width: auto;
  padding: 12px;
  border-radius: 7px;
  right: 4px;
  font-size: 15px;
}